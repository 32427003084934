<template>
  <div class="content">
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">{{ info.networkName }} Explorer</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-3 col-6">
            <div class="small-box bg-info">
              <div class="inner">
                <h3>{{ info.newestBlockNumber }}</h3>
                <p>Newest block number</p>
              </div>
              <div class="icon">
                <i class="ion ion-bag"></i>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-6">
            <div class="small-box bg-success">
              <div class="inner">
                <h3>
                  {{ info.blockTime }}<sup style="font-size: 20px">s</sup>
                </h3>
                <p>Block period from {{ info.networkStartTime }}</p>
              </div>
              <div class="icon">
                <i class="ion ion-stats-bars"></i>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-6">
            <div class="small-box bg-warning">
              <div class="inner">
                <h3>
                  {{ info.coinTotalSupply }}
                  <sup style="font-size: 20px"> {{ info.coinName }}</sup>
                </h3>

                <p>Native asset supply</p>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-6">
            <div class="small-box bg-danger">
              <div class="inner">
                <h3>
                  {{ info.coinPrice }}
                  <sup style="font-size: 20px"> {{ info.coinPriceUnit }}</sup>
                </h3>
                <p>Native asset value</p>
              </div>
              <div class="icon">
                <i class="ion ion-pie-graph"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="card card-primary card-outline">
              <div class="card-header border-0">
                <div class="d-flex justify-content-between">
                  <h3 class="card-title">Newest blocks</h3>


                  <router-link to="/blocks/"> All blocks</router-link>

                </div>
              </div>
              <div class="card-body">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>Block number</th>
                      <th>Address</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(block, index) in blocks" :key="index">
                      <td>{{ block.number_10 }}</td>
                      <td>
                        <router-link :to="'/block/' + block.hash">{{
                          block.hash
                        }}</router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card card-primary card-outline">
              <div class="card-header border-0">
                <div class="d-flex justify-content-between">
                  <h3 class="card-title">Newest transactions</h3>
                  <router-link to="/blocks/"> All transactions</router-link>
                </div>
              </div>
              <div class="card-body">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>TransactionIndex</th>
                      <th>Address</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(transaction, index) in transactions"
                      :key="index"
                    >
                      <td>{{ transaction.transactionIndex }}</td>
                      <td>
                        <router-link :to="'/transaction/' + transaction.hash">{{
                          transaction.hash
                        }}</router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "HomeView",
  components: {},
  data() {
    return {
      blocks: [],
      transactions: [],
    };
  },
  computed: {
    ...mapState(["info"]),
  },
  methods: {
    getBlocks() {
      this.$http.get("block/getLatest").then((res) => {
        this.blocks = res.data.data;
      });
    },
    getTransactions() {
      this.$http.get("transaction/getLatest").then((res) => {
        this.transactions = res.data.data;
      });
    },
  },
  mounted() {
    this.$store.dispatch("getInfo");
    this.getBlocks();
    this.getTransactions();

    let _this = this;
    window.interTask = setInterval(function () {
      _this.getBlocks();
      _this.getTransactions();
    }, 5000);
  },
  beforeUnmount() {
    clearInterval(window.interTask);
  },
};
</script>
