import { createConditionalExpression } from '@vue/compiler-core';
import axios from 'axios'
import { createStore } from 'vuex'


export default createStore({
  state: {
    info: {
      networkName: null,
      networkId: null,
      networkStartTime: null,
      websiteName: "MagicScan",
      newestBlockNumber: null,
      blockNumber: null,
      transactionNumber: null,
      blockTime: null,
      coinName: null,
      coinTotalSupply: null,
      coinPrice: null,
      coinPriceUnit: null,
    }
  },
  getters: {
  },
  mutations: {
    setInfo: (state, data) => { state.info = data }
  },
  actions: {
    getInfo(context) {
      axios.get("init/info").then((res) => {
        console.log(res);
        context.commit('setInfo', res.data.data)
      });
    }

  },
  modules: {
  }
})
