import { createRouter, createWebHistory,createWebHashHistory } from 'vue-router'
import home from '../views/home.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: home
  },
  {
    path: '/blocks',
    name: 'blocksHome',
    component: () => import(/* webpackChunkName: "about" */ '../views/blockList.vue')
  },
  {
    path: '/blocks/:page',
    name: 'blocksPage',
    component: () => import(/* webpackChunkName: "about" */ '../views/blockList.vue')
  },

  {
    path: '/block/:hash',
    name: 'blockDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/blockDetail.vue')
  },
  {
    path: '/transactions',
    name: 'transactionHome',
    component: () => import(/* webpackChunkName: "about" */ '../views/transactionList.vue')
  },
  {
    path: '/transactions/:page',
    name: 'transactionPage',
    component: () => import(/* webpackChunkName: "about" */ '../views/transactionList.vue')
  },
  {
    path: '/transaction/:hash',
    name: 'transactionDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/transactionDetail.vue')
  },
]
const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
