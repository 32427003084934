import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import moment from 'moment'

axios.defaults.baseURL = 'https://scanapi.sl.cool/api/';
if (process.env.NODE_ENV == "development") {
    axios.defaults.baseURL = 'http://evmgate.com/api/';
}

const app = createApp(App);
app.use(store).use(router).use(moment).mount('#app')
app.config.globalProperties.$http = axios;
app.config.globalProperties.$moment = moment;