<template>
  <!-- Navbar -->
  <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#" role="button"
          ><i class="fas fa-bars"></i
        ></a>
      </li>
      <li class="nav-item d-none d-sm-inline-block">
        <a href="http://www.sl.cool" target="_blank" class="nav-link"
          >SL.cool</a
        >
      </li>
      <li class="nav-item d-none d-sm-inline-block">
        <a href="http://47.240.255.200:3000/" target="_blank" class="nav-link"
          >Network state</a
        >
      </li>
    </ul>

    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto">
      <!-- Navbar Search -->
      <li class="nav-item">
        <a class="nav-link" data-widget="navbar-search" href="#" role="button">
          <i class="fas fa-search"></i>
        </a>
        <div class="navbar-search-block">
          <form class="form-inline">
            <div class="input-group input-group-sm">
              <input
                class="form-control form-control-navbar"
                type="search"
                placeholder="Search"
                aria-label="Search"
              />
              <div class="input-group-append">
                <button class="btn btn-navbar" type="submit">
                  <i class="fas fa-search"></i>
                </button>
                <button
                  class="btn btn-navbar"
                  type="button"
                  data-widget="navbar-search"
                >
                  <i class="fas fa-times"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </li>
      <li class="nav-item">
        <a class="nav-link" data-widget="fullscreen" href="#" role="button">
          <i class="fas fa-expand-arrows-alt"></i>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          data-widget="control-sidebar"
          data-slide="true"
          href="#"
          role="button"
        >
          <i class="fas fa-th-large"></i>
        </a>
      </li>
    </ul>
  </nav>
  <!-- /.navbar -->

  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <a href="#" class="brand-link">
      <img
        src="/dist/img/AdminLTELogo.png"
        :alt="info.websiteName"
        class="brand-image img-circle elevation-3"
        style="opacity: 0.8"
      />
      <span class="brand-text font-weight-light">{{ info.websiteName }}</span>
    </a>

    <div class="sidebar">
      <!-- <div class="form-inline mt-3">
        <div class="input-group" data-widget="sidebar-search">
          <input
            class="form-control form-control-sidebar"
            type="search"
            placeholder="Search"
            aria-label="Search"
          />
          <div class="input-group-append">
            <button class="btn btn-sidebar">
              <i class="fas fa-search fa-fw"></i>
            </button>
          </div>
        </div>
      </div> -->

      <nav class="mt-2">
        <ul
          class="nav nav-pills nav-sidebar flex-column"
          data-widget="treeview"
          role="menu"
          data-accordion="false"
        >
          <li class="nav-item">
            <router-link to="/" class="nav-link"
              ><i class="nav-icon fas fa-calendar-alt"></i>
              <p>Home</p></router-link
            >
          </li>

          <li class="nav-item">
            <router-link to="/blocks/" class="nav-link">
              <i class="nav-icon fas fa-calendar-alt"></i>
              <p>Blocks</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/transactions/" class="nav-link">
              <i class="nav-icon fas fa-calendar-alt"></i>
              <p>Transactions</p>
            </router-link>
          </li>
        </ul>
      </nav>
    </div>
  </aside>

  <div class="content-wrapper">
    <router-view :key="$route.fullPath"></router-view>
  </div>
  <aside class="control-sidebar control-sidebar-dark"></aside>
  <footer class="main-footer">
    <strong
      >Copyright &copy; 2014-2021
      <a href="https://adminlte.io">AdminLTE.io</a>.</strong
    >
    All rights reserved.
    <div class="float-right d-none d-sm-inline-block"><b>Version</b> 3.2.0</div>
  </footer>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "rootLayout",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapState(["info"]),
  },
  methods: {},
  mounted() {
    this.$store.dispatch("getInfo");
    let _this = this;
    setInterval(function () {
      _this.$store.dispatch("getInfo");
    }, 5000);
  },
};
</script>
